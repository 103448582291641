/** @type {import('tailwindcss').Config} */
 module.exports = {
  mode: "jit",
  content: ["./src/**/*.{ts,tsx}"],
  darkMode: "class",
  theme: {
    extend: {
      semantic: {
        color: {
          background: {
            neutral: {
              primary: {
                default: "var(--nj-semantic-color-background-neutral-primary-default)",
                hovered: "var(--nj-semantic-color-background-neutral-primary-hovered)",
                focused: "var(--nj-semantic-color-background-neutral-primary-focused)",
                pressed: "var(--nj-semantic-color-background-neutral-primary-pressed)",
                selected: "var(--nj-semantic-color-background-neutral-primary-selected)",
                translucent: {
                  default: "var(--nj-semantic-color-background-neutral-primary-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-neutral-primary-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-neutral-primary-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-neutral-primary-translucent-pressed)",
                  selected: "var(--nj-semantic-color-background-neutral-primary-translucent-selected)"
                }
              },
              secondary: {
                default: "var(--nj-semantic-color-background-neutral-secondary-default)",
                hovered: "var(--nj-semantic-color-background-neutral-secondary-hovered)",
                focused: "var(--nj-semantic-color-background-neutral-secondary-focused)",
                pressed: "var(--nj-semantic-color-background-neutral-secondary-pressed)",
                translucent: {
                  default: "var(--nj-semantic-color-background-neutral-secondary-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-neutral-secondary-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-neutral-secondary-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-neutral-secondary-translucent-pressed)"
                }
              },
              tertiary: {
                default: "var(--nj-semantic-color-background-neutral-tertiary-default)",
                hovered: "var(--nj-semantic-color-background-neutral-tertiary-hovered)",
                focused: "var(--nj-semantic-color-background-neutral-tertiary-focused)",
                pressed: "var(--nj-semantic-color-background-neutral-tertiary-pressed)",
                translucent: {
                  default: "var(--nj-semantic-color-background-neutral-tertiary-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-neutral-tertiary-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-neutral-tertiary-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-neutral-tertiary-translucent-pressed)"
                }
              },
              solid: {
                default: "var(--nj-semantic-color-background-neutral-solid-default)",
                hovered: "var(--nj-semantic-color-background-neutral-solid-hovered)",
                focused: "var(--nj-semantic-color-background-neutral-solid-focused)",
                pressed: "var(--nj-semantic-color-background-neutral-solid-pressed)"
              },
              inverse: {
                default: "var(--nj-semantic-color-background-neutral-inverse-default)",
                hovered: "var(--nj-semantic-color-background-neutral-inverse-hovered)",
                focused: "var(--nj-semantic-color-background-neutral-inverse-focused)",
                pressed: "var(--nj-semantic-color-background-neutral-inverse-pressed)",
                translucent: {
                  default: "var(--nj-semantic-color-background-neutral-inverse-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-neutral-inverse-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-neutral-inverse-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-neutral-inverse-translucent-pressed)"
                }
              },
              spotlight: {
                default: "var(--nj-semantic-color-background-neutral-spotlight-default)"
              }
            },
            brand: {
              primary: {
                translucent: {
                  default: "var(--nj-semantic-color-background-brand-primary-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-brand-primary-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-brand-primary-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-brand-primary-translucent-pressed)"
                }
              },
              secondary: {
                default: "var(--nj-semantic-color-background-brand-secondary-default)",
                hovered: "var(--nj-semantic-color-background-brand-secondary-hovered)",
                focused: "var(--nj-semantic-color-background-brand-secondary-focused)",
                pressed: "var(--nj-semantic-color-background-brand-secondary-pressed)",
                translucent: {
                  default: "var(--nj-semantic-color-background-brand-secondary-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-brand-secondary-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-brand-secondary-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-brand-secondary-translucent-pressed)"
                }
              },
              tertiary: {
                default: "var(--nj-semantic-color-background-brand-tertiary-default)",
                hovered: "var(--nj-semantic-color-background-brand-tertiary-hovered)",
                focused: "var(--nj-semantic-color-background-brand-tertiary-focused)",
                pressed: "var(--nj-semantic-color-background-brand-tertiary-pressed)",
                translucent: {
                  default: "var(--nj-semantic-color-background-brand-tertiary-translucent-default)",
                  hovered: "var(--nj-semantic-color-background-brand-tertiary-translucent-hovered)",
                  focused: "var(--nj-semantic-color-background-brand-tertiary-translucent-focused)",
                  pressed: "var(--nj-semantic-color-background-brand-tertiary-translucent-pressed)"
                }
              },
              solid: {
                default: "var(--nj-semantic-color-background-brand-solid-default)",
                hovered: "var(--nj-semantic-color-background-brand-solid-hovered)",
                focused: "var(--nj-semantic-color-background-brand-solid-focused)",
                pressed: "var(--nj-semantic-color-background-brand-solid-pressed)"
              },
              spotlight: {
                default: "var(--nj-semantic-color-background-brand-spotlight-default)"
              }
            },
            status: {
              danger: {
                primary: {
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-danger-primary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-danger-primary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-danger-primary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-danger-primary-translucent-pressed)",
                    selected: "var(--nj-semantic-color-background-status-danger-primary-translucent-selected)"
                  }
                },
                secondary: {
                  default: "var(--nj-semantic-color-background-status-danger-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-status-danger-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-danger-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-danger-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-status-danger-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-status-danger-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-danger-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-danger-tertiary-pressed)",
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-danger-tertiary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-danger-tertiary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-danger-tertiary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-danger-tertiary-translucent-pressed)"
                  }
                },
                solid: {
                  default: "var(--nj-semantic-color-background-status-danger-solid-default)",
                  hovered: "var(--nj-semantic-color-background-status-danger-solid-hovered)",
                  pressed: "var(--nj-semantic-color-background-status-danger-solid-pressed)",
                  focused: "var(--nj-semantic-color-background-status-danger-solid-focused)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-status-danger-spotlight-default)"
                }
              },
              success: {
                primary: {
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-success-primary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-success-primary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-success-primary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-success-primary-translucent-pressed)",
                    selected: "var(--nj-semantic-color-background-status-success-primary-translucent-selected)"
                  }
                },
                secondary: {
                  default: "var(--nj-semantic-color-background-status-success-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-status-success-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-success-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-success-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-status-success-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-status-success-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-success-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-success-tertiary-pressed)",
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-success-tertiary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-success-tertiary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-success-tertiary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-success-tertiary-translucent-pressed)"
                  }
                },
                solid: {
                  default: "var(--nj-semantic-color-background-status-success-solid-default)",
                  hovered: "var(--nj-semantic-color-background-status-success-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-status-success-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-status-success-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-status-success-spotlight-default)"
                }
              },
              warning: {
                primary: {
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-warning-primary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-warning-primary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-warning-primary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-warning-primary-translucent-pressed)",
                    selected: "var(--nj-semantic-color-background-status-warning-primary-translucent-selected)"
                  }
                },
                secondary: {
                  default: "var(--nj-semantic-color-background-status-warning-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-status-warning-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-warning-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-warning-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-status-warning-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-status-warning-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-warning-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-warning-tertiary-pressed)",
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-warning-tertiary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-warning-tertiary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-warning-tertiary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-warning-tertiary-translucent-pressed)"
                  }
                },
                solid: {
                  default: "var(--nj-semantic-color-background-status-warning-solid-default)",
                  hovered: "var(--nj-semantic-color-background-status-warning-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-status-warning-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-status-warning-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-status-warning-spotlight-default)"
                }
              },
              discovery: {
                primary: {
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-discovery-primary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-discovery-primary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-discovery-primary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-discovery-primary-translucent-pressed)",
                    selected: "var(--nj-semantic-color-background-status-discovery-primary-translucent-selected)"
                  }
                },
                secondary: {
                  default: "var(--nj-semantic-color-background-status-discovery-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-status-discovery-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-discovery-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-discovery-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-status-discovery-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-status-discovery-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-discovery-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-discovery-tertiary-pressed)",
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-discovery-tertiary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-discovery-tertiary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-discovery-tertiary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-discovery-tertiary-translucent-pressed)"
                  }
                },
                solid: {
                  default: "var(--nj-semantic-color-background-status-discovery-solid-default)",
                  hovered: "var(--nj-semantic-color-background-status-discovery-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-status-discovery-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-status-discovery-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-status-discovery-spotlight-default)"
                }
              },
              information: {
                primary: {
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-information-primary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-information-primary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-information-primary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-information-primary-translucent-pressed)",
                    selected: "var(--nj-semantic-color-background-status-information-primary-translucent-selected)"
                  }
                },
                solid: {
                  default: "var(--nj-semantic-color-background-status-information-solid-default)",
                  hovered: "var(--nj-semantic-color-background-status-information-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-status-information-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-status-information-solid-pressed)"
                },
                secondary: {
                  default: "var(--nj-semantic-color-background-status-information-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-status-information-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-information-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-information-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-status-information-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-status-information-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-information-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-information-tertiary-pressed)",
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-information-tertiary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-information-tertiary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-information-tertiary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-information-tertiary-translucent-pressed)"
                  }
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-status-information-spotlight-default)"
                }
              },
              planet: {
                primary: {
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-planet-primary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-planet-primary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-planet-primary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-planet-primary-translucent-pressed)",
                    selected: "var(--nj-semantic-color-background-status-planet-primary-translucent-selected)"
                  }
                },
                solid: {
                  default: "var(--nj-semantic-color-background-status-planet-solid-default)",
                  hovered: "var(--nj-semantic-color-background-status-planet-solid-hovered)",
                  pressed: "var(--nj-semantic-color-background-status-planet-solid-pressed)",
                  focused: "var(--nj-semantic-color-background-status-planet-solid-focused)"
                },
                secondary: {
                  default: "var(--nj-semantic-color-background-status-planet-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-status-planet-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-planet-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-planet-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-status-planet-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-status-planet-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-status-planet-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-status-planet-tertiary-pressed)",
                  translucent: {
                    default: "var(--nj-semantic-color-background-status-planet-tertiary-translucent-default)",
                    hovered: "var(--nj-semantic-color-background-status-planet-tertiary-translucent-hovered)",
                    focused: "var(--nj-semantic-color-background-status-planet-tertiary-translucent-focused)",
                    pressed: "var(--nj-semantic-color-background-status-planet-tertiary-translucent-pressed)"
                  }
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-status-planet-spotlight-default)"
                }
              }
            },
            accent: {
              pink: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-pink-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-pink-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-pink-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-pink-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-pink-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-pink-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-pink-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-pink-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-pink-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-pink-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-pink-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-pink-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-pink-spotlight-default)"
                }
              },
              red: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-red-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-red-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-red-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-red-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-red-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-red-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-red-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-red-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-red-solid-default)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-red-spotlight-default)"
                }
              },
              orange: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-orange-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-orange-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-orange-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-orange-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-orange-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-orange-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-orange-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-orange-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-orange-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-orange-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-orange-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-orange-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-orange-spotlight-default)"
                }
              },
              yellow: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-yellow-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-yellow-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-yellow-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-yellow-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-yellow-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-yellow-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-yellow-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-yellow-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-yellow-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-yellow-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-yellow-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-yellow-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-yellow-spotlight-default)"
                }
              },
              green: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-green-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-green-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-green-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-green-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-green-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-green-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-green-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-green-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-green-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-green-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-green-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-green-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-green-spotlight-default)"
                }
              },
              grey: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-grey-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-grey-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-grey-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-grey-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-grey-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-grey-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-grey-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-grey-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-grey-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-grey-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-grey-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-grey-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-grey-spotlight-default)"
                }
              },
              blue: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-blue-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-blue-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-blue-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-blue-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-blue-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-blue-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-blue-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-blue-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-blue-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-blue-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-blue-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-blue-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-blue-spotlight-default)"
                }
              },
              ultramarine: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-ultramarine-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-ultramarine-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-ultramarine-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-ultramarine-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-ultramarine-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-ultramarine-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-ultramarine-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-ultramarine-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-ultramarine-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-ultramarine-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-ultramarine-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-ultramarine-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-ultramarine-spotlight-default)"
                }
              },
              purple: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-purple-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-purple-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-purple-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-purple-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-purple-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-purple-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-purple-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-purple-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-purple-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-purple-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-purple-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-purple-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-purple-spotlight-default)"
                }
              },
              teal: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-teal-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-teal-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-teal-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-teal-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-teal-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-teal-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-teal-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-teal-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-teal-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-teal-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-teal-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-teal-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-teal-spotlight-default)"
                }
              },
              lime: {
                secondary: {
                  default: "var(--nj-semantic-color-background-accent-lime-secondary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-lime-secondary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-lime-secondary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-lime-secondary-pressed)"
                },
                tertiary: {
                  default: "var(--nj-semantic-color-background-accent-lime-tertiary-default)",
                  hovered: "var(--nj-semantic-color-background-accent-lime-tertiary-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-lime-tertiary-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-lime-tertiary-pressed)"
                },
                solid: {
                  default: "var(--nj-semantic-color-background-accent-lime-solid-default)",
                  hovered: "var(--nj-semantic-color-background-accent-lime-solid-hovered)",
                  focused: "var(--nj-semantic-color-background-accent-lime-solid-focused)",
                  pressed: "var(--nj-semantic-color-background-accent-lime-solid-pressed)"
                },
                spotlight: {
                  default: "var(--nj-semantic-color-background-accent-lime-spotlight-default)"
                }
              }
            },
            additional: {
              engieRay: {
                default: "var(--nj-semantic-color-background-additional-engie-ray-default)"
              },
              signature: {
                default: "var(--nj-semantic-color-background-additional-signature-default)"
              }
            }
          },
          border: {
            neutral: {
              minimal: "var(--nj-semantic-color-border-neutral-minimal)",
              subtle: "var(--nj-semantic-color-border-neutral-subtle)",
              moderate: "var(--nj-semantic-color-border-neutral-moderate)",
              strong: "var(--nj-semantic-color-border-neutral-strong)",
              heavy: "var(--nj-semantic-color-border-neutral-heavy)",
              inverse: "var(--nj-semantic-color-border-neutral-inverse)"
            },
            brand: {
              subtle: "var(--nj-semantic-color-border-brand-subtle)",
              moderate: "var(--nj-semantic-color-border-brand-moderate)",
              strong: "var(--nj-semantic-color-border-brand-strong)"
            },
            status: {
              danger: {
                subtle: "var(--nj-semantic-color-border-status-danger-subtle)",
                moderate: "var(--nj-semantic-color-border-status-danger-moderate)",
                strong: "var(--nj-semantic-color-border-status-danger-strong)"
              },
              warning: {
                subtle: "var(--nj-semantic-color-border-status-warning-subtle)",
                moderate: "var(--nj-semantic-color-border-status-warning-moderate)",
                strong: "var(--nj-semantic-color-border-status-warning-strong)"
              },
              success: {
                subtle: "var(--nj-semantic-color-border-status-success-subtle)",
                moderate: "var(--nj-semantic-color-border-status-success-moderate)",
                strong: "var(--nj-semantic-color-border-status-success-strong)"
              },
              discovery: {
                subtle: "var(--nj-semantic-color-border-status-discovery-subtle)",
                moderate: "var(--nj-semantic-color-border-status-discovery-moderate)",
                strong: "var(--nj-semantic-color-border-status-discovery-strong)"
              },
              information: {
                subtle: "var(--nj-semantic-color-border-status-information-subtle)",
                moderate: "var(--nj-semantic-color-border-status-information-moderate)",
                strong: "var(--nj-semantic-color-border-status-information-strong)"
              },
              planet: {
                subtle: "var(--nj-semantic-color-border-status-planet-subtle)",
                moderate: "var(--nj-semantic-color-border-status-planet-moderate)",
                strong: "var(--nj-semantic-color-border-status-planet-strong)"
              }
            },
            accent: {
              pink: {
                subtle: "var(--nj-semantic-color-border-accent-pink-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-pink-moderate)",
                strong: "var(--nj-semantic-color-border-accent-pink-strong)"
              },
              red: {
                subtle: "var(--nj-semantic-color-border-accent-red-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-red-moderate)",
                strong: "var(--nj-semantic-color-border-accent-red-strong)"
              },
              orange: {
                subtle: "var(--nj-semantic-color-border-accent-orange-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-orange-moderate)",
                strong: "var(--nj-semantic-color-border-accent-orange-strong)"
              },
              yellow: {
                subtle: "var(--nj-semantic-color-border-accent-yellow-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-yellow-moderate)",
                strong: "var(--nj-semantic-color-border-accent-yellow-strong)"
              },
              green: {
                subtle: "var(--nj-semantic-color-border-accent-green-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-green-moderate)",
                strong: "var(--nj-semantic-color-border-accent-green-strong)"
              },
              grey: {
                subtle: "var(--nj-semantic-color-border-accent-grey-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-grey-moderate)",
                strong: "var(--nj-semantic-color-border-accent-grey-strong)"
              },
              blue: {
                subtle: "var(--nj-semantic-color-border-accent-blue-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-blue-moderate)",
                strong: "var(--nj-semantic-color-border-accent-blue-strong)"
              },
              ultramarine: {
                subtle: "var(--nj-semantic-color-border-accent-ultramarine-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-ultramarine-moderate)",
                strong: "var(--nj-semantic-color-border-accent-ultramarine-strong)"
              },
              purple: {
                subtle: "var(--nj-semantic-color-border-accent-purple-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-purple-moderate)",
                strong: "var(--nj-semantic-color-border-accent-purple-strong)"
              },
              teal: {
                subtle: "var(--nj-semantic-color-border-accent-teal-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-teal-moderate)",
                strong: "var(--nj-semantic-color-border-accent-teal-strong)"
              },
              lime: {
                subtle: "var(--nj-semantic-color-border-accent-lime-subtle)",
                moderate: "var(--nj-semantic-color-border-accent-lime-moderate)",
                strong: "var(--nj-semantic-color-border-accent-lime-strong)"
              }
            }
          },
          icon: {
            neutral: {
              primary: {
                default: "var(--nj-semantic-color-icon-neutral-primary-default)"
              },
              secondary: {
                default: "var(--nj-semantic-color-icon-neutral-secondary-default)",
                hovered: "var(--nj-semantic-color-icon-neutral-secondary-hovered)",
                focused: "var(--nj-semantic-color-icon-neutral-secondary-focused)",
                pressed: "var(--nj-semantic-color-icon-neutral-secondary-pressed)"
              },
              tertiary: {
                default: "var(--nj-semantic-color-icon-neutral-tertiary-default)",
                hovered: "var(--nj-semantic-color-icon-neutral-tertiary-hovered)",
                focused: "var(--nj-semantic-color-icon-neutral-tertiary-focused)",
                pressed: "var(--nj-semantic-color-icon-neutral-tertiary-pressed)"
              },
              contrast: {
                default: "var(--nj-semantic-color-icon-neutral-contrast-default)",
                hovered: "var(--nj-semantic-color-icon-neutral-contrast-hovered)",
                focused: "var(--nj-semantic-color-icon-neutral-contrast-focused)",
                pressed: "var(--nj-semantic-color-icon-neutral-contrast-pressed)"
              },
              inverse: {
                default: "var(--nj-semantic-color-icon-neutral-inverse-default)"
              }
            },
            brand: {
              primary: {
                default: "var(--nj-semantic-color-icon-brand-primary-default)",
                hovered: "var(--nj-semantic-color-icon-brand-primary-hovered)",
                focused: "var(--nj-semantic-color-icon-brand-primary-focused)",
                pressed: "var(--nj-semantic-color-icon-brand-primary-pressed)"
              },
              contrast: {
                default: "var(--nj-semantic-color-icon-brand-contrast-default)"
              }
            },
            status: {
              danger: {
                primary: {
                  default: "var(--nj-semantic-color-icon-status-danger-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-status-danger-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-status-danger-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-status-danger-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-status-danger-contrast-default)"
                }
              },
              success: {
                primary: {
                  default: "var(--nj-semantic-color-icon-status-success-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-status-success-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-status-success-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-status-success-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-status-success-contrast-default)"
                }
              },
              warning: {
                primary: {
                  default: "var(--nj-semantic-color-icon-status-warning-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-status-warning-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-status-warning-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-status-warning-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-status-warning-contrast-default)"
                }
              },
              discovery: {
                primary: {
                  default: "var(--nj-semantic-color-icon-status-discovery-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-status-discovery-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-status-discovery-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-status-discovery-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-status-discovery-contrast-default)"
                }
              },
              information: {
                primary: {
                  default: "var(--nj-semantic-color-icon-status-information-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-status-information-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-status-information-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-status-information-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-status-information-contrast-default)"
                }
              },
              planet: {
                primary: {
                  default: "var(--nj-semantic-color-icon-status-planet-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-status-planet-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-status-planet-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-status-planet-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-status-planet-contrast-default)"
                }
              }
            },
            accent: {
              pink: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-pink-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-pink-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-pink-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-pink-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-pink-contrast-default)"
                }
              },
              red: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-red-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-red-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-red-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-red-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-red-contrast-default)"
                }
              },
              orange: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-orange-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-orange-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-orange-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-orange-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-orange-contrast-default)"
                }
              },
              yellow: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-yellow-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-yellow-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-yellow-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-yellow-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-yellow-contrast-default)"
                }
              },
              green: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-green-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-green-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-green-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-green-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-green-contrast-default)"
                }
              },
              grey: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-grey-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-grey-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-grey-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-grey-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-grey-contrast-default)"
                }
              },
              blue: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-blue-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-blue-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-blue-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-blue-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-blue-contrast-default)"
                }
              },
              ultramarine: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-ultramarine-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-ultramarine-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-ultramarine-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-ultramarine-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-ultramarine-contrast-default)"
                }
              },
              purple: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-purple-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-purple-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-purple-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-purple-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-purple-contrast-default)"
                }
              },
              teal: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-teal-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-teal-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-teal-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-teal-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-teal-contrast-default)"
                }
              },
              lime: {
                primary: {
                  default: "var(--nj-semantic-color-icon-accent-lime-primary-default)",
                  hovered: "var(--nj-semantic-color-icon-accent-lime-primary-hovered)",
                  focused: "var(--nj-semantic-color-icon-accent-lime-primary-focused)",
                  pressed: "var(--nj-semantic-color-icon-accent-lime-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-icon-accent-lime-contrast-default)"
                }
              }
            },
            additional: {
              signature: {
                default: "var(--nj-semantic-color-icon-additional-signature-default)"
              }
            }
          },
          text: {
            neutral: {
              primary: {
                default: "var(--nj-semantic-color-text-neutral-primary-default)",
                hovered: "var(--nj-semantic-color-text-neutral-primary-hovered)",
                focused: "var(--nj-semantic-color-text-neutral-primary-focused)",
                pressed: "var(--nj-semantic-color-text-neutral-primary-pressed)",
                notInvertible: {
                  default: "var(--nj-semantic-color-text-neutral-primary-not-invertible-default)",
                  hovered: "var(--nj-semantic-color-text-neutral-primary-not-invertible-hovered)",
                  focused: "var(--nj-semantic-color-text-neutral-primary-not-invertible-focused)",
                  pressed: "var(--nj-semantic-color-text-neutral-primary-not-invertible-pressed)"
                }
              },
              secondary: {
                default: "var(--nj-semantic-color-text-neutral-secondary-default)"
              },
              tertiary: {
                default: "var(--nj-semantic-color-text-neutral-tertiary-default)",
                hovered: "var(--nj-semantic-color-text-neutral-tertiary-hovered)",
                focused: "var(--nj-semantic-color-text-neutral-tertiary-focused)",
                pressed: "var(--nj-semantic-color-text-neutral-tertiary-pressed)"
              },
              contrast: {
                default: "var(--nj-semantic-color-text-neutral-contrast-default)",
                hovered: "var(--nj-semantic-color-text-neutral-contrast-hovered)",
                focused: "var(--nj-semantic-color-text-neutral-contrast-focused)",
                pressed: "var(--nj-semantic-color-text-neutral-contrast-pressed)"
              },
              inverse: {
                default: "var(--nj-semantic-color-text-neutral-inverse-default)",
                hovered: "var(--nj-semantic-color-text-neutral-inverse-hovered)",
                focused: "var(--nj-semantic-color-text-neutral-inverse-focused)",
                pressed: "var(--nj-semantic-color-text-neutral-inverse-pressed)",
                notInvertible: {
                  default: "var(--nj-semantic-color-text-neutral-inverse-not-invertible-default)",
                  hovered: "var(--nj-semantic-color-text-neutral-inverse-not-invertible-hovered)",
                  focused: "var(--nj-semantic-color-text-neutral-inverse-not-invertible-focused)",
                  pressed: "var(--nj-semantic-color-text-neutral-inverse-not-invertible-pressed)"
                }
              }
            },
            brand: {
              primary: {
                default: "var(--nj-semantic-color-text-brand-primary-default)",
                hovered: "var(--nj-semantic-color-text-brand-primary-hovered)",
                focused: "var(--nj-semantic-color-text-brand-primary-focused)",
                pressed: "var(--nj-semantic-color-text-brand-primary-pressed)"
              },
              contrast: {
                default: "var(--nj-semantic-color-text-brand-contrast-default)",
                hovered: "var(--nj-semantic-color-text-brand-contrast-hovered)",
                focused: "var(--nj-semantic-color-text-brand-contrast-focused)",
                pressed: "var(--nj-semantic-color-text-brand-contrast-pressed)"
              }
            },
            status: {
              danger: {
                primary: {
                  default: "var(--nj-semantic-color-text-status-danger-primary-default)",
                  hovered: "var(--nj-semantic-color-text-status-danger-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-status-danger-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-status-danger-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-status-danger-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-status-danger-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-status-danger-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-status-danger-contrast-pressed)"
                }
              },
              warning: {
                primary: {
                  default: "var(--nj-semantic-color-text-status-warning-primary-default)",
                  hovered: "var(--nj-semantic-color-text-status-warning-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-status-warning-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-status-warning-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-status-warning-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-status-warning-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-status-warning-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-status-warning-contrast-pressed)"
                }
              },
              success: {
                primary: {
                  default: "var(--nj-semantic-color-text-status-success-primary-default)",
                  hovered: "var(--nj-semantic-color-text-status-success-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-status-success-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-status-success-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-status-success-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-status-success-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-status-success-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-status-success-contrast-pressed)"
                }
              },
              information: {
                primary: {
                  default: "var(--nj-semantic-color-text-status-information-primary-default)",
                  hovered: "var(--nj-semantic-color-text-status-information-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-status-information-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-status-information-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-status-information-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-status-information-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-status-information-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-status-information-contrast-pressed)"
                }
              },
              discovery: {
                primary: {
                  default: "var(--nj-semantic-color-text-status-discovery-primary-default)",
                  hovered: "var(--nj-semantic-color-text-status-discovery-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-status-discovery-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-status-discovery-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-status-discovery-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-status-discovery-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-status-discovery-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-status-discovery-contrast-pressed)"
                }
              },
              planet: {
                primary: {
                  default: "var(--nj-semantic-color-text-status-planet-primary-default)",
                  hovered: "var(--nj-semantic-color-text-status-planet-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-status-planet-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-status-planet-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-status-planet-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-status-planet-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-status-planet-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-status-planet-contrast-pressed)"
                }
              }
            },
            accent: {
              pink: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-pink-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-pink-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-pink-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-pink-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-pink-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-pink-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-pink-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-pink-contrast-pressed)"
                }
              },
              red: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-red-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-red-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-red-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-red-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-red-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-red-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-red-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-red-contrast-pressed)"
                }
              },
              orange: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-orange-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-orange-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-orange-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-orange-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-orange-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-orange-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-orange-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-orange-contrast-pressed)"
                }
              },
              yellow: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-yellow-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-yellow-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-yellow-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-yellow-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-yellow-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-yellow-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-yellow-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-yellow-contrast-pressed)"
                }
              },
              green: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-green-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-green-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-green-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-green-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-green-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-green-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-green-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-green-contrast-pressed)"
                }
              },
              grey: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-grey-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-grey-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-grey-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-grey-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-grey-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-grey-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-grey-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-grey-contrast-pressed)"
                }
              },
              blue: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-blue-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-blue-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-blue-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-blue-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-blue-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-blue-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-blue-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-blue-contrast-pressed)"
                }
              },
              ultramarine: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-ultramarine-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-ultramarine-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-ultramarine-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-ultramarine-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-ultramarine-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-ultramarine-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-ultramarine-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-ultramarine-contrast-pressed)"
                }
              },
              purple: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-purple-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-purple-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-purple-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-purple-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-purple-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-purple-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-purple-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-purple-contrast-pressed)"
                }
              },
              teal: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-teal-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-teal-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-teal-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-teal-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-teal-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-teal-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-teal-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-teal-contrast-pressed)"
                }
              },
              lime: {
                primary: {
                  default: "var(--nj-semantic-color-text-accent-lime-primary-default)",
                  hovered: "var(--nj-semantic-color-text-accent-lime-primary-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-lime-primary-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-lime-primary-pressed)"
                },
                contrast: {
                  default: "var(--nj-semantic-color-text-accent-lime-contrast-default)",
                  hovered: "var(--nj-semantic-color-text-accent-lime-contrast-hovered)",
                  focused: "var(--nj-semantic-color-text-accent-lime-contrast-focused)",
                  pressed: "var(--nj-semantic-color-text-accent-lime-contrast-pressed)"
                }
              }
            },
            additional: {
              signature: {
                default: "var(--nj-semantic-color-text-additional-signature-default)"
              }
            }
          }
        },
        elevation: {
          shadow: {
        "2Dp": "var(--nj-semantic-elevation-shadow-2-dp)",
        "4Dp": "var(--nj-semantic-elevation-shadow-4-dp)",
        "6Dp": "var(--nj-semantic-elevation-shadow-6-dp)",
        "8Dp": "var(--nj-semantic-elevation-shadow-8-dp)",
        "16Dp": "var(--nj-semantic-elevation-shadow-16-dp)",
        "24Dp": "var(--nj-semantic-elevation-shadow-24-dp)"
          },
          zIndex: {
            dropdown: "var(--nj-semantic-elevation-z-index-dropdown)",
            sticky: "var(--nj-semantic-elevation-z-index-sticky)",
            fixed: "var(--nj-semantic-elevation-z-index-fixed)",
            modalBackdrop: "var(--nj-semantic-elevation-z-index-modal-backdrop)",
            modal: "var(--nj-semantic-elevation-z-index-modal)",
            popover: "var(--nj-semantic-elevation-z-index-popover)",
            tooltip: "var(--nj-semantic-elevation-z-index-tooltip)",
            toastContainer: "var(--nj-semantic-elevation-z-index-toast-container)",
            toast: "var(--nj-semantic-elevation-z-index-toast)"
          }
        },
        font: {
          family: {
            sansSerif: "var(--nj-semantic-font-family-sans-serif)",
            monospace: "var(--nj-semantic-font-family-monospace)",
            default: "var(--nj-semantic-font-family-default)"
          },
          weight: {
            light: "var(--nj-semantic-font-weight-light)",
            normal: "var(--nj-semantic-font-weight-normal)",
            bold: "var(--nj-semantic-font-weight-bold)",
            black: "var(--nj-semantic-font-weight-black)"
          },
          line: {
            height: {
              default: "var(--nj-semantic-font-line-height-default)",
              sansSerif: "var(--nj-semantic-font-line-height-sans-serif)"
            }
          }
        },
        motion: {
          easing: {
            fastOutSlowIn: "var(--nj-semantic-motion-easing-fast-out-slow-in)",
            linearOutSlowIn: "var(--nj-semantic-motion-easing-linear-out-slow-in)",
            fastOutLinearIn: "var(--nj-semantic-motion-easing-fast-out-linear-in)"
          }
        },
        opacity: {
          disabled: "var(--nj-semantic-opacity-disabled)"
        },
        size: {
          border: {
            width: "var(--nj-semantic-size-border-width)",
            radius: {
              none: "var(--nj-semantic-size-border-radius-none)",
              sm: "var(--nj-semantic-size-border-radius-sm)",
              md: "var(--nj-semantic-size-border-radius-md)",
              lg: "var(--nj-semantic-size-border-radius-lg)",
              pill: "var(--nj-semantic-size-border-radius-pill)"
            },
            focusRing: {
              width: "var(--nj-semantic-size-border-focus-ring-width)",
              offset: "var(--nj-semantic-size-border-focus-ring-offset)"
            }
          },
          font: {
            content: {
              1: "var(--nj-semantic-size-font-content-1)",
              2: "var(--nj-semantic-size-font-content-2)",
              3: "var(--nj-semantic-size-font-content-3)"
            },
            heading: {
              1: "var(--nj-semantic-size-font-heading-1)",
              2: "var(--nj-semantic-size-font-heading-2)",
              3: "var(--nj-semantic-size-font-heading-3)",
              4: "var(--nj-semantic-size-font-heading-4)",
              5: "var(--nj-semantic-size-font-heading-5)",
              6: "var(--nj-semantic-size-font-heading-6)"
            },
            display: {
              1: "var(--nj-semantic-size-font-display-1)"
            }
          },
          icon: {
            xs: "var(--nj-semantic-size-icon-xs)",
            sm: "var(--nj-semantic-size-icon-sm)",
            md: "var(--nj-semantic-size-icon-md)",
            lg: "var(--nj-semantic-size-icon-lg)",
            xl: "var(--nj-semantic-size-icon-xl)",
            xxl: "var(--nj-semantic-size-icon-xxl)",
        "3xl": "var(--nj-semantic-size-icon-3xl)",
        "4xl": "var(--nj-semantic-size-icon-4xl)",
        "5xl": "var(--nj-semantic-size-icon-5xl)",
        "6xl": "var(--nj-semantic-size-icon-6xl)"
          },
          spacing: {
            0: "var(--nj-semantic-size-spacing-0)",
            2: "var(--nj-semantic-size-spacing-2)",
            4: "var(--nj-semantic-size-spacing-4)",
            8: "var(--nj-semantic-size-spacing-8)",
            12: "var(--nj-semantic-size-spacing-12)",
            16: "var(--nj-semantic-size-spacing-16)",
            20: "var(--nj-semantic-size-spacing-20)",
            24: "var(--nj-semantic-size-spacing-24)",
            32: "var(--nj-semantic-size-spacing-32)",
            48: "var(--nj-semantic-size-spacing-48)",
            64: "var(--nj-semantic-size-spacing-64)",
            80: "var(--nj-semantic-size-spacing-80)",
            96: "var(--nj-semantic-size-spacing-96)",
            112: "var(--nj-semantic-size-spacing-112)"
          }
        }
      },
      core: {
        color: {
          grey: {
            0: "var(--nj-core-color-grey-0)",
            100: "var(--nj-core-color-grey-100)",
            200: "var(--nj-core-color-grey-200)",
            300: "var(--nj-core-color-grey-300)",
            400: "var(--nj-core-color-grey-400)",
            500: "var(--nj-core-color-grey-500)",
            600: "var(--nj-core-color-grey-600)",
            700: "var(--nj-core-color-grey-700)",
            800: "var(--nj-core-color-grey-800)",
            900: "var(--nj-core-color-grey-900)",
            1000: "var(--nj-core-color-grey-1000)",
            alpha: {
              0: "var(--nj-core-color-grey-alpha-0)",
              100: "var(--nj-core-color-grey-alpha-100)",
              200: "var(--nj-core-color-grey-alpha-200)",
              300: "var(--nj-core-color-grey-alpha-300)",
              400: "var(--nj-core-color-grey-alpha-400)",
              500: "var(--nj-core-color-grey-alpha-500)",
              inverse: {
                0: "var(--nj-core-color-grey-alpha-inverse-0)",
                100: "var(--nj-core-color-grey-alpha-inverse-100)",
                200: "var(--nj-core-color-grey-alpha-inverse-200)",
                300: "var(--nj-core-color-grey-alpha-inverse-300)",
                400: "var(--nj-core-color-grey-alpha-inverse-400)",
                500: "var(--nj-core-color-grey-alpha-inverse-500)"
              }
            }
          },
          pink: {
            100: "var(--nj-core-color-pink-100)",
            200: "var(--nj-core-color-pink-200)",
            300: "var(--nj-core-color-pink-300)",
            400: "var(--nj-core-color-pink-400)",
            500: "var(--nj-core-color-pink-500)",
            600: "var(--nj-core-color-pink-600)",
            700: "var(--nj-core-color-pink-700)",
            800: "var(--nj-core-color-pink-800)",
            900: "var(--nj-core-color-pink-900)"
          },
          red: {
            100: "var(--nj-core-color-red-100)",
            200: "var(--nj-core-color-red-200)",
            300: "var(--nj-core-color-red-300)",
            400: "var(--nj-core-color-red-400)",
            500: "var(--nj-core-color-red-500)",
            600: "var(--nj-core-color-red-600)",
            700: "var(--nj-core-color-red-700)",
            800: "var(--nj-core-color-red-800)",
            900: "var(--nj-core-color-red-900)",
            alpha: {
              0: "var(--nj-core-color-red-alpha-0)",
              100: "var(--nj-core-color-red-alpha-100)",
              200: "var(--nj-core-color-red-alpha-200)",
              300: "var(--nj-core-color-red-alpha-300)",
              400: "var(--nj-core-color-red-alpha-400)"
            }
          },
          orange: {
            100: "var(--nj-core-color-orange-100)",
            200: "var(--nj-core-color-orange-200)",
            300: "var(--nj-core-color-orange-300)",
            400: "var(--nj-core-color-orange-400)",
            500: "var(--nj-core-color-orange-500)",
            600: "var(--nj-core-color-orange-600)",
            700: "var(--nj-core-color-orange-700)",
            800: "var(--nj-core-color-orange-800)",
            900: "var(--nj-core-color-orange-900)",
            alpha: {
              0: "var(--nj-core-color-orange-alpha-0)",
              100: "var(--nj-core-color-orange-alpha-100)",
              200: "var(--nj-core-color-orange-alpha-200)",
              300: "var(--nj-core-color-orange-alpha-300)",
              400: "var(--nj-core-color-orange-alpha-400)"
            }
          },
          yellow: {
            100: "var(--nj-core-color-yellow-100)",
            200: "var(--nj-core-color-yellow-200)",
            300: "var(--nj-core-color-yellow-300)",
            400: "var(--nj-core-color-yellow-400)",
            500: "var(--nj-core-color-yellow-500)",
            600: "var(--nj-core-color-yellow-600)",
            700: "var(--nj-core-color-yellow-700)",
            800: "var(--nj-core-color-yellow-800)",
            900: "var(--nj-core-color-yellow-900)"
          },
          green: {
            100: "var(--nj-core-color-green-100)",
            200: "var(--nj-core-color-green-200)",
            300: "var(--nj-core-color-green-300)",
            400: "var(--nj-core-color-green-400)",
            500: "var(--nj-core-color-green-500)",
            600: "var(--nj-core-color-green-600)",
            700: "var(--nj-core-color-green-700)",
            800: "var(--nj-core-color-green-800)",
            900: "var(--nj-core-color-green-900)",
            alpha: {
              0: "var(--nj-core-color-green-alpha-0)",
              100: "var(--nj-core-color-green-alpha-100)",
              200: "var(--nj-core-color-green-alpha-200)",
              300: "var(--nj-core-color-green-alpha-300)",
              400: "var(--nj-core-color-green-alpha-400)"
            }
          },
          blue: {
            100: "var(--nj-core-color-blue-100)",
            200: "var(--nj-core-color-blue-200)",
            300: "var(--nj-core-color-blue-300)",
            400: "var(--nj-core-color-blue-400)",
            500: "var(--nj-core-color-blue-500)",
            600: "var(--nj-core-color-blue-600)",
            700: "var(--nj-core-color-blue-700)",
            800: "var(--nj-core-color-blue-800)",
            900: "var(--nj-core-color-blue-900)",
            alpha: {
              0: "var(--nj-core-color-blue-alpha-0)",
              100: "var(--nj-core-color-blue-alpha-100)",
              200: "var(--nj-core-color-blue-alpha-200)",
              300: "var(--nj-core-color-blue-alpha-300)",
              400: "var(--nj-core-color-blue-alpha-400)"
            }
          },
          ultramarine: {
            100: "var(--nj-core-color-ultramarine-100)",
            200: "var(--nj-core-color-ultramarine-200)",
            300: "var(--nj-core-color-ultramarine-300)",
            400: "var(--nj-core-color-ultramarine-400)",
            500: "var(--nj-core-color-ultramarine-500)",
            600: "var(--nj-core-color-ultramarine-600)",
            700: "var(--nj-core-color-ultramarine-700)",
            800: "var(--nj-core-color-ultramarine-800)",
            900: "var(--nj-core-color-ultramarine-900)"
          },
          purple: {
            100: "var(--nj-core-color-purple-100)",
            200: "var(--nj-core-color-purple-200)",
            300: "var(--nj-core-color-purple-300)",
            400: "var(--nj-core-color-purple-400)",
            500: "var(--nj-core-color-purple-500)",
            600: "var(--nj-core-color-purple-600)",
            700: "var(--nj-core-color-purple-700)",
            800: "var(--nj-core-color-purple-800)",
            900: "var(--nj-core-color-purple-900)",
            alpha: {
              0: "var(--nj-core-color-purple-alpha-0)",
              100: "var(--nj-core-color-purple-alpha-100)",
              200: "var(--nj-core-color-purple-alpha-200)",
              300: "var(--nj-core-color-purple-alpha-300)",
              400: "var(--nj-core-color-purple-alpha-400)"
            }
          },
          teal: {
            100: "var(--nj-core-color-teal-100)",
            200: "var(--nj-core-color-teal-200)",
            300: "var(--nj-core-color-teal-300)",
            400: "var(--nj-core-color-teal-400)",
            500: "var(--nj-core-color-teal-500)",
            600: "var(--nj-core-color-teal-600)",
            700: "var(--nj-core-color-teal-700)",
            800: "var(--nj-core-color-teal-800)",
            900: "var(--nj-core-color-teal-900)",
            alpha: {
              0: "var(--nj-core-color-teal-alpha-0)",
              100: "var(--nj-core-color-teal-alpha-100)",
              200: "var(--nj-core-color-teal-alpha-200)",
              300: "var(--nj-core-color-teal-alpha-300)",
              400: "var(--nj-core-color-teal-alpha-400)"
            }
          },
          lime: {
            100: "var(--nj-core-color-lime-100)",
            200: "var(--nj-core-color-lime-200)",
            300: "var(--nj-core-color-lime-300)",
            400: "var(--nj-core-color-lime-400)",
            500: "var(--nj-core-color-lime-500)",
            600: "var(--nj-core-color-lime-600)",
            700: "var(--nj-core-color-lime-700)",
            800: "var(--nj-core-color-lime-800)",
            900: "var(--nj-core-color-lime-900)"
          },
          engie: {
            blue: "var(--nj-core-color-engie-blue)",
            green: "var(--nj-core-color-engie-green)"
          },
          reference: {
            brand: {
              100: "var(--nj-core-color-reference-brand-100)",
              200: "var(--nj-core-color-reference-brand-200)",
              300: "var(--nj-core-color-reference-brand-300)",
              400: "var(--nj-core-color-reference-brand-400)",
              500: "var(--nj-core-color-reference-brand-500)",
              600: "var(--nj-core-color-reference-brand-600)",
              700: "var(--nj-core-color-reference-brand-700)",
              800: "var(--nj-core-color-reference-brand-800)",
              900: "var(--nj-core-color-reference-brand-900)",
              alpha: {
                0: "var(--nj-core-color-reference-brand-alpha-0)",
                100: "var(--nj-core-color-reference-brand-alpha-100)",
                200: "var(--nj-core-color-reference-brand-alpha-200)",
                300: "var(--nj-core-color-reference-brand-alpha-300)",
                400: "var(--nj-core-color-reference-brand-alpha-400)"
              }
            },
            neutral: {
              0: "var(--nj-core-color-reference-neutral-0)",
              100: "var(--nj-core-color-reference-neutral-100)",
              200: "var(--nj-core-color-reference-neutral-200)",
              300: "var(--nj-core-color-reference-neutral-300)",
              400: "var(--nj-core-color-reference-neutral-400)",
              500: "var(--nj-core-color-reference-neutral-500)",
              600: "var(--nj-core-color-reference-neutral-600)",
              700: "var(--nj-core-color-reference-neutral-700)",
              800: "var(--nj-core-color-reference-neutral-800)",
              900: "var(--nj-core-color-reference-neutral-900)",
              1000: "var(--nj-core-color-reference-neutral-1000)",
              alpha: {
                0: "var(--nj-core-color-reference-neutral-alpha-0)",
                100: "var(--nj-core-color-reference-neutral-alpha-100)",
                200: "var(--nj-core-color-reference-neutral-alpha-200)",
                300: "var(--nj-core-color-reference-neutral-alpha-300)",
                400: "var(--nj-core-color-reference-neutral-alpha-400)",
                500: "var(--nj-core-color-reference-neutral-alpha-500)",
                inverse: {
                  0: "var(--nj-core-color-reference-neutral-alpha-inverse-0)",
                  100: "var(--nj-core-color-reference-neutral-alpha-inverse-100)",
                  200: "var(--nj-core-color-reference-neutral-alpha-inverse-200)",
                  300: "var(--nj-core-color-reference-neutral-alpha-inverse-300)",
                  400: "var(--nj-core-color-reference-neutral-alpha-inverse-400)",
                  500: "var(--nj-core-color-reference-neutral-alpha-inverse-500)"
                }
              }
            },
            status: {
              danger: {
                100: "var(--nj-core-color-reference-status-danger-100)",
                200: "var(--nj-core-color-reference-status-danger-200)",
                300: "var(--nj-core-color-reference-status-danger-300)",
                400: "var(--nj-core-color-reference-status-danger-400)",
                500: "var(--nj-core-color-reference-status-danger-500)",
                600: "var(--nj-core-color-reference-status-danger-600)",
                700: "var(--nj-core-color-reference-status-danger-700)",
                800: "var(--nj-core-color-reference-status-danger-800)",
                900: "var(--nj-core-color-reference-status-danger-900)",
                alpha: {
                  0: "var(--nj-core-color-reference-status-danger-alpha-0)",
                  100: "var(--nj-core-color-reference-status-danger-alpha-100)",
                  200: "var(--nj-core-color-reference-status-danger-alpha-200)",
                  300: "var(--nj-core-color-reference-status-danger-alpha-300)",
                  400: "var(--nj-core-color-reference-status-danger-alpha-400)"
                }
              },
              success: {
                100: "var(--nj-core-color-reference-status-success-100)",
                200: "var(--nj-core-color-reference-status-success-200)",
                300: "var(--nj-core-color-reference-status-success-300)",
                400: "var(--nj-core-color-reference-status-success-400)",
                500: "var(--nj-core-color-reference-status-success-500)",
                600: "var(--nj-core-color-reference-status-success-600)",
                700: "var(--nj-core-color-reference-status-success-700)",
                800: "var(--nj-core-color-reference-status-success-800)",
                900: "var(--nj-core-color-reference-status-success-900)",
                alpha: {
                  0: "var(--nj-core-color-reference-status-success-alpha-0)",
                  100: "var(--nj-core-color-reference-status-success-alpha-100)",
                  200: "var(--nj-core-color-reference-status-success-alpha-200)",
                  300: "var(--nj-core-color-reference-status-success-alpha-300)",
                  400: "var(--nj-core-color-reference-status-success-alpha-400)"
                }
              },
              warning: {
                100: "var(--nj-core-color-reference-status-warning-100)",
                200: "var(--nj-core-color-reference-status-warning-200)",
                300: "var(--nj-core-color-reference-status-warning-300)",
                400: "var(--nj-core-color-reference-status-warning-400)",
                500: "var(--nj-core-color-reference-status-warning-500)",
                600: "var(--nj-core-color-reference-status-warning-600)",
                700: "var(--nj-core-color-reference-status-warning-700)",
                800: "var(--nj-core-color-reference-status-warning-800)",
                900: "var(--nj-core-color-reference-status-warning-900)",
                alpha: {
                  0: "var(--nj-core-color-reference-status-warning-alpha-0)",
                  100: "var(--nj-core-color-reference-status-warning-alpha-100)",
                  200: "var(--nj-core-color-reference-status-warning-alpha-200)",
                  300: "var(--nj-core-color-reference-status-warning-alpha-300)",
                  400: "var(--nj-core-color-reference-status-warning-alpha-400)"
                }
              },
              discovery: {
                100: "var(--nj-core-color-reference-status-discovery-100)",
                200: "var(--nj-core-color-reference-status-discovery-200)",
                300: "var(--nj-core-color-reference-status-discovery-300)",
                400: "var(--nj-core-color-reference-status-discovery-400)",
                500: "var(--nj-core-color-reference-status-discovery-500)",
                600: "var(--nj-core-color-reference-status-discovery-600)",
                700: "var(--nj-core-color-reference-status-discovery-700)",
                800: "var(--nj-core-color-reference-status-discovery-800)",
                900: "var(--nj-core-color-reference-status-discovery-900)",
                alpha: {
                  0: "var(--nj-core-color-reference-status-discovery-alpha-0)",
                  100: "var(--nj-core-color-reference-status-discovery-alpha-100)",
                  200: "var(--nj-core-color-reference-status-discovery-alpha-200)",
                  300: "var(--nj-core-color-reference-status-discovery-alpha-300)",
                  400: "var(--nj-core-color-reference-status-discovery-alpha-400)"
                }
              },
              information: {
                100: "var(--nj-core-color-reference-status-information-100)",
                200: "var(--nj-core-color-reference-status-information-200)",
                300: "var(--nj-core-color-reference-status-information-300)",
                400: "var(--nj-core-color-reference-status-information-400)",
                500: "var(--nj-core-color-reference-status-information-500)",
                600: "var(--nj-core-color-reference-status-information-600)",
                700: "var(--nj-core-color-reference-status-information-700)",
                800: "var(--nj-core-color-reference-status-information-800)",
                900: "var(--nj-core-color-reference-status-information-900)",
                alpha: {
                  0: "var(--nj-core-color-reference-status-information-alpha-0)",
                  100: "var(--nj-core-color-reference-status-information-alpha-100)",
                  200: "var(--nj-core-color-reference-status-information-alpha-200)",
                  300: "var(--nj-core-color-reference-status-information-alpha-300)",
                  400: "var(--nj-core-color-reference-status-information-alpha-400)"
                }
              },
              planet: {
                100: "var(--nj-core-color-reference-status-planet-100)",
                200: "var(--nj-core-color-reference-status-planet-200)",
                300: "var(--nj-core-color-reference-status-planet-300)",
                400: "var(--nj-core-color-reference-status-planet-400)",
                500: "var(--nj-core-color-reference-status-planet-500)",
                600: "var(--nj-core-color-reference-status-planet-600)",
                700: "var(--nj-core-color-reference-status-planet-700)",
                800: "var(--nj-core-color-reference-status-planet-800)",
                900: "var(--nj-core-color-reference-status-planet-900)",
                alpha: {
                  0: "var(--nj-core-color-reference-status-planet-alpha-0)",
                  100: "var(--nj-core-color-reference-status-planet-alpha-100)",
                  200: "var(--nj-core-color-reference-status-planet-alpha-200)",
                  300: "var(--nj-core-color-reference-status-planet-alpha-300)",
                  400: "var(--nj-core-color-reference-status-planet-alpha-400)"
                }
              }
            }
          }
        },
        elevation: {
          zIndex: {
            0: "var(--nj-core-elevation-z-index-0)",
            1: "var(--nj-core-elevation-z-index-1)",
            10: "var(--nj-core-elevation-z-index-10)",
            20: "var(--nj-core-elevation-z-index-20)",
            30: "var(--nj-core-elevation-z-index-30)",
            40: "var(--nj-core-elevation-z-index-40)",
            50: "var(--nj-core-elevation-z-index-50)",
            60: "var(--nj-core-elevation-z-index-60)",
            70: "var(--nj-core-elevation-z-index-70)",
            80: "var(--nj-core-elevation-z-index-80)",
            90: "var(--nj-core-elevation-z-index-90)",
            100: "var(--nj-core-elevation-z-index-100)",
            110: "var(--nj-core-elevation-z-index-110)",
            120: "var(--nj-core-elevation-z-index-120)",
            130: "var(--nj-core-elevation-z-index-130)",
            140: "var(--nj-core-elevation-z-index-140)",
            150: "var(--nj-core-elevation-z-index-150)",
            160: "var(--nj-core-elevation-z-index-160)",
            170: "var(--nj-core-elevation-z-index-170)",
            180: "var(--nj-core-elevation-z-index-180)",
            190: "var(--nj-core-elevation-z-index-190)",
            200: "var(--nj-core-elevation-z-index-200)",
            210: "var(--nj-core-elevation-z-index-210)",
            220: "var(--nj-core-elevation-z-index-220)",
            230: "var(--nj-core-elevation-z-index-230)",
            240: "var(--nj-core-elevation-z-index-240)",
            250: "var(--nj-core-elevation-z-index-250)",
            260: "var(--nj-core-elevation-z-index-260)",
            270: "var(--nj-core-elevation-z-index-270)",
            280: "var(--nj-core-elevation-z-index-280)",
            290: "var(--nj-core-elevation-z-index-290)",
            300: "var(--nj-core-elevation-z-index-300)",
            310: "var(--nj-core-elevation-z-index-310)",
            320: "var(--nj-core-elevation-z-index-320)",
            330: "var(--nj-core-elevation-z-index-330)",
            340: "var(--nj-core-elevation-z-index-340)",
            350: "var(--nj-core-elevation-z-index-350)",
            360: "var(--nj-core-elevation-z-index-360)",
            370: "var(--nj-core-elevation-z-index-370)",
            380: "var(--nj-core-elevation-z-index-380)",
            390: "var(--nj-core-elevation-z-index-390)",
            400: "var(--nj-core-elevation-z-index-400)",
            410: "var(--nj-core-elevation-z-index-410)",
            420: "var(--nj-core-elevation-z-index-420)",
            430: "var(--nj-core-elevation-z-index-430)",
            440: "var(--nj-core-elevation-z-index-440)",
            450: "var(--nj-core-elevation-z-index-450)",
            460: "var(--nj-core-elevation-z-index-460)",
            470: "var(--nj-core-elevation-z-index-470)",
            480: "var(--nj-core-elevation-z-index-480)",
            490: "var(--nj-core-elevation-z-index-490)",
            500: "var(--nj-core-elevation-z-index-500)"
          }
        },
        opacity: {
          50: "var(--nj-core-opacity-50)",
          100: "var(--nj-core-opacity-100)",
          150: "var(--nj-core-opacity-150)",
          200: "var(--nj-core-opacity-200)",
          250: "var(--nj-core-opacity-250)",
          300: "var(--nj-core-opacity-300)",
          350: "var(--nj-core-opacity-350)",
          400: "var(--nj-core-opacity-400)",
          450: "var(--nj-core-opacity-450)",
          500: "var(--nj-core-opacity-500)",
          550: "var(--nj-core-opacity-550)",
          600: "var(--nj-core-opacity-600)",
          650: "var(--nj-core-opacity-650)",
          700: "var(--nj-core-opacity-700)",
          750: "var(--nj-core-opacity-750)",
          800: "var(--nj-core-opacity-800)",
          850: "var(--nj-core-opacity-850)",
          900: "var(--nj-core-opacity-900)",
          950: "var(--nj-core-opacity-950)",
          1000: "var(--nj-core-opacity-1000)"
        },
        size: {
          0: "var(--nj-core-size-0)",
          1: "var(--nj-core-size-1)",
          2: "var(--nj-core-size-2)",
          4: "var(--nj-core-size-4)",
          6: "var(--nj-core-size-6)",
          8: "var(--nj-core-size-8)",
          10: "var(--nj-core-size-10)",
          12: "var(--nj-core-size-12)",
          14: "var(--nj-core-size-14)",
          16: "var(--nj-core-size-16)",
          18: "var(--nj-core-size-18)",
          20: "var(--nj-core-size-20)",
          22: "var(--nj-core-size-22)",
          24: "var(--nj-core-size-24)",
          28: "var(--nj-core-size-28)",
          30: "var(--nj-core-size-30)",
          32: "var(--nj-core-size-32)",
          34: "var(--nj-core-size-34)",
          36: "var(--nj-core-size-36)",
          38: "var(--nj-core-size-38)",
          40: "var(--nj-core-size-40)",
          42: "var(--nj-core-size-42)",
          44: "var(--nj-core-size-44)",
          46: "var(--nj-core-size-46)",
          48: "var(--nj-core-size-48)",
          50: "var(--nj-core-size-50)",
          52: "var(--nj-core-size-52)",
          54: "var(--nj-core-size-54)",
          56: "var(--nj-core-size-56)",
          58: "var(--nj-core-size-58)",
          64: "var(--nj-core-size-64)",
          76: "var(--nj-core-size-76)",
          80: "var(--nj-core-size-80)",
          96: "var(--nj-core-size-96)",
          112: "var(--nj-core-size-112)",
          116: "var(--nj-core-size-116)",
          128: "var(--nj-core-size-128)",
          9999: "var(--nj-core-size-9999)"
        }
      },
      component: {
        avatar: {
          size: {
            sm: "var(--nj-component-avatar-size-sm)",
            base: "var(--nj-component-avatar-size-base)",
            lg: "var(--nj-component-avatar-size-lg)",
            xl: "var(--nj-component-avatar-size-xl)"
          }
        },
        backdrop: {
          color: {
            background: "var(--nj-component-backdrop-color-background)"
          }
        },
        fab: {
          size: {
            sm: "var(--nj-component-fab-size-sm)",
            base: "var(--nj-component-fab-size-base)"
          }
        },
        skeleton: {
          color: {
            background: "var(--nj-component-skeleton-color-background)"
          }
        }
      }
    },
  },
}